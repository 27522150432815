<template>
  <div class="container">
    <div class="main-content">
      <div class="row">
        <div class="col h3 text-success">1. Quản trị hệ thống , vận hành máy chủ server, bảo mật thông tin hệ thống</div>
      </div>
      <ul>
        <li>Nhận quản trị website công ty, doanh nghiệp, đảm bảo an toàn thông tin và cập nhật tính năng theo yêu cầu</li>
        <li>Hổ trợ giải đáp về chuyên ngành công nghệ thông tin cho nhân viên và người sử dụng</li>
      </ul>
      <div class="row">
        <div class="col">
          <div class="h3 text-success">2. Thiết kế hệ thống website theo yêu cầu</div>
          <ul>
            <li>Chúng tôi chuyên thiết kế hệ thống phần mềm quản lý chuổi kinh doanh cho công ty, với đội ngũ kỹ sư nhiều năm kinh nghiệm, chúng tôi có thể đáp ứng hầu hết yêu cầu để tạo ra phần mềm tốt nhất</li>
            <li>Chúng tôi là những người đam mê công nghệ và tự tin mang đến những công nghệ tốt nhất trên thị trường đến với khách hàng</li>
            <li>Đảm bảo Hệ thống website chạy được trên tất cả thiết bị, dễ dàng truy cập và làm việc mọi lúc mọi nơi</li>
            <li>Cài đặt đơn giản, hệ thống được thiết kế hiện đại dể thao tác, dể sửa dụng.</li>
            <li>Giá cả hợp lý</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="h3 text-success">3. Chuyển đổi hệ thống từ công nghệ củ qua công nghệ mới</div>
          <ul>
            <li>Tư vấn khách hàng chọn công nghệ mới phù hợp</li>
            <li>Đánh giá hệ thống củ đang sử dụng, lập quy trình chuyển đổi qua công nghệ mới theo yêu cầu, cải tiến tính năng hiện có để đáp ứng nhu cầu sử dụng mới của doanh nghiệp</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="h3 text-success">4. Cung cấp nhân sự công nghệ làm việc toàn thời gian, bán thời gian</div>
          <ul>
            <li>Cung cấp nhân sự chuyên nghiệp, tham gia dự án toàn thời gian, bán thời gian</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col"><label>ĐĂNG KÝ SỬ DỤNG MIỄN PHÍ 100 NGÀY</label> <a href="/dang-ky?code=cafe" style="background-color: #ed1c24;" class="btn btn-danger">
      <i aria-hidden="true" class="fa fa-hand-o-right"></i> Đăng ký</a></div>
      </div>
      <div class="row border border-light mb-1">
        <div class="col fb-comments"
        data-numposts="5" data-width="100%" data-lazy="true"></div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: 'Gioithieu',
}
</script>
